import API from "@/api"

function tree2list(tree, list = []) {
    tree.forEach(item => {
        if (item.devices && item.devices.length) {
            list.push(...item.devices)
        }
        if (item.child && item.child.length) {
            list.push(...tree2list(item.child))
        }
    })
    return list
}

class Parser {
    static uav_data_parser(metadata) {
        let data = JSON.parse(metadata)
        return data
    }
}


let data_source = null

export default {

    async init_data_source() {
        let cesium_layer = this.cesium_layer
        let viewer = cesium_layer.viewer()
        data_source = new Cesium.CustomDataSource("OBSERVE_LAYER")
        await viewer.dataSources.add(data_source)
    },

    destroy_data_source() {
        if (data_source) {
            let cesium_layer = this.cesium_layer
            let viewer = cesium_layer.viewer()
            data_source.entities.removeAll()
            viewer.dataSources.remove(data_source, true)
            data_source = null
        }
    },

    trans_location_data(device, uav_data) {
        if (!uav_data) {
            return uav_data
        }

        if (!uav_data.data) {
            return uav_data.data
        }

        let cesium_layer = this.cesium_layer
        let uavInfo = uav_data.data.uavInfo
            
        let position = Cesium.Cartesian3.fromDegrees(
            uavInfo.longitude * 1,
            uavInfo.latitude * 1,
            uavInfo.height * 1
        );

        let yaw = Cesium.Math.toRadians(uavInfo.yaw + 90),
            pitch = Cesium.Math.toRadians(uavInfo.pitch),
            roll = Cesium.Math.toRadians(uavInfo.roll),
            hpr = new Cesium.HeadingPitchRoll(yaw, pitch, roll),
            orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
            );

        let positions = [];
        if (uavInfo.longitude != 0 && uavInfo.latitude != 0) {
            positions.push(position);
        }
        return {
            position,
            positions,
            orientation,
            device
        };
    },


    async list() {

        if (data_source) {
            this.uav_destroy_data_source()
        }

        await this.uav_init_data_source()

        let list = await API.DEVICE.List({ cate: 1 })

        if (list && list.length) {
            list = tree2list(list)
        }

        list.forEach(device => {

            if (device && device.deviceHardId) {

                let cesium_layer = this.cesium_layer
                let uav_data = Parser.uav_data_parser(device.latestData)
                let data = this.uav_trans_location_data(device, uav_data)
                let entities = data_source.entities

                if (data && data.device) {

                    let model = entities.getById(`layout_model_${data.device.deviceHardId}`)
                    if (!model) {
                        data.entity_id = `layout_model_${data.device.deviceHardId}`
                        model = cesium_layer.add_model(entities, data)
                    }
                }
            }
        })
    },

    get_track(viewer, data) {
        const { deviceHardId, flightTrajectory, taskStatus, longitude, latitude, height } = data
        this.uav.fullList.push(longitude, latitude)
        if (!viewer.entities.getById(`home_flightTrajectory_${deviceHardId}`)) {
            viewer.entities.add({
                id: `home_flightTrajectory_${deviceHardId}`,
                position: new Cesium.CallbackProperty(() => {
                    return Cesium.Cartesian3.fromDegrees(longitude, latitude, height)
                }),
                polyline: {
                    positions: new Cesium.CallbackProperty(() => {
                        let list = []
                        flightTrajectory.forEach(item => {
                            list.push(item.pointLongitude, item.pointLatitude)
                        });
                        return Cesium.Cartesian3.fromDegreesArray(list);
                    }, false),
                    width: 5,
                    material: new Cesium.PolylineDashMaterialProperty({
                        color: Cesium.Color.fromCssColorString("#6DD400"),
                        dashLength: 20 //短划线长度
                    })
                },
                billboard: {
                    width: 48,
                    height: 48,
                    image: require("@/assets/images/factor/zysb/1.png"),
                },
            })
            viewer.entities.add({
                id: `home_finished_flightTrajectory_${deviceHardId}`,
                polyline: {
                    positions: new Cesium.CallbackProperty(() => {
                        if (this.uav.fullList) {
                            return Cesium.Cartesian3.fromDegreesArray(this.uav.fullList)
                        } else {
                            return
                        }
                    }, false),
                    width: 5,
                    material: new Cesium.PolylineDashMaterialProperty({
                        color: Cesium.Color.fromCssColorString("#6DD400"),
                        dashLength: 20 //短划线长度
                    })
                },
            })
            viewer.camera.flyTo({
                // destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, height),
                destination: Cesium.Cartesian3.fromDegrees(flightTrajectory[0].pointLongitude, flightTrajectory[0].pointLatitude, flightTrajectory[0].pointHeight),
            });
        }
    }
}